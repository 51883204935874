import { toast } from "react-toastify";
import { ActionType } from "./actionTypes";
import DocumentService from "../services/DocumentService";
import { IReportOptions } from "../interfaces";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { sanitizeData } from "../helpers/utils";
import { ObjectID } from "bson";
import * as buildInfo from "../buildNumber.json";
export function startPreload() {
  return {
    type: ActionType.START_PRELOAD,
  };
}

export function setSendEmailStatus(status: string) {
  return {
    type: ActionType.UPDATE_SEND_EMAIL_STATUS,
    status,
  };
}

export function stopPreload() {
  return {
    type: ActionType.STOP_PRELOAD,
  };
}

function CreateLogMessage(dispatch: any) {
  return function logMessage(
    message: string | Error | any | any[],
    color: string = "black",
    _isSystemMessage: boolean = false
  ) {
    const time = new Date().toUTCString();
    // Replace -1 value by "N/A"
    if (typeof message === "object") {
      Object.keys(message).forEach((key) => {
        if (typeof message[key] === "number" && message[key] === -1) {
          message[key] = "N/A";
        }
      });
    }

    dispatch({
      type: ActionType.LOG_MESSAGE,
      payload: {
        color,
        time,
        message,
      },
    });
  };
}

function logPingMessage() {
  // console.log(pingStatus);
}

function logSpeedMessage() {
  // console.log(message);
}

export function initTests() {
  return async (dispatch: any) => {
    dispatch(startPreload());
    dispatch(getLayout());
    // dispatch(updateColorThresholds(config.colorThresholds));
  };
}

export function getLayout() {
  return async (dispatch: any) => {
    try {
      const result = await DocumentService.getLayout();
      if (result.report) {
        // add report-pdf when in url to pdf mode
        document.body.className += `report-${result.report}`;
      }
      if (result.config.faviconPath !== "none") {
        const linkTag = document.createElement("link");
        linkTag.href = result.config.faviconPath;
        linkTag.rel = "shortcut icon";
        document.head.appendChild(linkTag);
      }
      if (result.config.contentDescription !== "") {
        const metaTagContent = document.createElement("meta");
        metaTagContent.name = "description";
        metaTagContent.content = result.config.contentDescription;
        document.head.appendChild(metaTagContent);
      }
      // prevent a page from appearing in Google Search
      if (result.config.disableSearchEngines) {
        const metaTag = document.createElement("meta");
        metaTag.name = "robots";
        metaTag.content = "noindex";
        document.head.appendChild(metaTag);
      }
      const titleTag: any = document.querySelector("html > head > title");
      titleTag.innerText = result.config.title;

      setTimeout(() => {
        dispatch({
          type: ActionType.GET_LAYOUT,
          payload: result,
        });
      }, 1);
      setTimeout(() => dispatch(stopPreload()), 1);
      setTimeout(() => dispatch(updateColorThresholds(result.config.colorThresholds)), 1);
    } catch (err) {
      console.log(err);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

function finishOneTest(
  dispatch: any,
  id: string,
  passedAtStart: number,
  passedAtEnd: number,
  totalTime: number,
  abort: Function
) {
  dispatch({
    type: ActionType.FINISH_ONE_TEST,
    payload: {
      id,
      passedAtStart,
      passedAtEnd,
      totalTime,
      abort,
    },
  });
}

const lazyLoadModule = async (
  awaitableImport: any,
  retries = 3,
  moduleFunction = "default"
): Promise<any> => {
  try {
    const module = await awaitableImport();
    return module[moduleFunction];
  } catch (err) {
    if (retries > 0) {
      const newRetries = retries - 1;
      console.log(`Lazy load module failed. Retries left: ${newRetries}`);
      await lazyLoadModule(awaitableImport, newRetries);
    } else {
      throw new Error(err.message);
    }
  }
};

export function startTest(testsList: string[], config: TestConfiguration) {
  return async (dispatch: any, getStore: any) => {
    try {
    } catch (err) {
      dispatch({
        type: ActionType.STOP_TESTS,
      });
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    dispatch({
      type: ActionType.RESET_TEST_RESULT,
    });
    dispatch({
      type: ActionType.SET_CUSTOM_ERROR,
      payload: null,
    });
    dispatch({
      type: ActionType.START_TESTS,
    });
    let result: any = null;
    const testRunId = new ObjectID().toString();
    try {
      const twillioTests = await lazyLoadModule(
        () => import("twillio-tests/tests"),
        3,
        "runAllTests"
      );

      result = await twillioTests(
        CreateLogMessage(dispatch),
        logPingMessage,
        logSpeedMessage,
        config,
        testsList,
        (
          id: string,
          passedAtStart: number,
          passedAtEnd: number,
          totalTime: number,
          abort: Function
        ) => finishOneTest(dispatch, id, passedAtStart, passedAtEnd, totalTime, abort)
      );

      result = sanitizeData(result);

      const createLog = CreateLogMessage(dispatch);
      createLog(`Product version: ${buildInfo.versionNumber}`, "black");
      createLog(`Build number: ${buildInfo.buildNumber}`, "black");
      createLog(`Built at: ${buildInfo.buildTime}`, "black");

      setTimeout(() => {
        dispatch({
          type: ActionType.UPDATE_TEST_RESULT,
          payload: result,
        });
        dispatch({
          type: ActionType.STOP_TESTS,
        });
      }, 1100);
    } catch (err) {
      console.error(err.stack);
      dispatch({
        type: ActionType.STOP_TESTS,
      });
    }

    try {
      const store = getStore();
      const additionalFields = {
        ...(config as any).fieldsValues,
        account: store.tests.config?.options?.account,
        invite: store.tests.config?.options?.invite,
      };

      const context = config.options.context ? JSON.parse(config.options.context) : null;

      const validateContext = typeof context === "object" && context !== null ? context : {};
      const data = {
        ...validateContext,
        testResult: result,
        logs: store.tests.logs,
        ...sanitizeData(additionalFields),
      };
      if (!config.disableAutoSave) {
        if (config.logRestrictAccess) {
          data.log_restrict_access = true;
        }
        if (testRunId) {
          dispatch({
            type: ActionType.GET_UUID,
            payload: testRunId,
          });
        }
        const uuid = await DocumentService.sendResult(testRunId, data, config);
        const resultId = typeof uuid.data === "object" ? uuid.data.resultId : uuid.data;
        if (!data.testResult.isCanceled) {
          if (!testRunId) {
            dispatch({
              type: ActionType.GET_UUID,
              payload: resultId,
            });
          }
          if (config.options.returnurl && config.options.returnurl !== "") {
            const account = config.options.account ?? "";
            const email = data.email ?? "";
            const testid = resultId;
            const status = result.status;
            const returnUrl =
              config.options.returnurl.indexOf("https://") >= 0
                ? config.options.returnurl.replace("https://", "")
                : config.options.returnurl;
            const redirectUrl = `https://${returnUrl}/?account=${account}&email=${email}&status=${status}&testid=${testid}`;
            window.location.href = redirectUrl;
          } else {
            const newUrl = `${resultId}${window.location.search}`;
            window.history.replaceState("", "", `${newUrl}`);
          }
          console.log(`Test results saved testId:${resultId} URL:${window.location.href}`, {
            config,
          });
        }
      } else {
        console.log("Save results is disabled", { config, data });
      }

      if (data.testResult.isCanceled) {
        window.location.reload();
        return;
      }

      /*
      toast.success(`UUID - ${uuid.data}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        draggable: false,
        closeOnClick: false,
      });
      */
    } catch (err) {
      dispatch({
        type: ActionType.SET_CUSTOM_ERROR,
        payload: {
          error: err.message
            ? err.message
            : "Unable to save test result, check your network connection",
        },
      });
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

export function sendEmail(fields: any, config: TestConfiguration, uuid: string) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ActionType.UPDATE_SEND_EMAIL_STATUS,
        status: "sending",
      });
      const result = await DocumentService.sendEmailPdf(fields, config, uuid);
      toast.success("Email sent.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch({
        type: ActionType.UPDATE_SEND_EMAIL_STATUS,
        status: "sent",
      });
      /*
      toast.success(`UUID - ${result.data}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        draggable: false,
        closeOnClick: false,
      });
      */
    } catch (err) {
      dispatch({
        type: ActionType.UPDATE_SEND_EMAIL_STATUS,
        status: null,
      });
      console.log(toast.error);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

export function sendReport(fieldOption: IReportOptions, config: TestConfiguration) {
  return async (dispatch: any, getStore: any) => {
    try {
      const store = getStore();
      const data = {
        ...fieldOption,
      };
      const result = await DocumentService.sendReport(data, store.document.uuid, config);
      toast.success("Test results sent", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log("send results", { uuid: result.data });
      /*
      toast.success(`UUID - ${result.data}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        draggable: false,
        closeOnClick: false,
      });
      */
    } catch (err) {
      console.log(toast.error);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

export function updateColorThresholds(colorThresholds: IColorThresholds | null) {
  return {
    type: ActionType.UPDATE_COLOR_THRESHOLDS,
    payload: colorThresholds,
  };
}
