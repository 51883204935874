export const Estimates = {
  Throughput: 16.9,
  CallQuality: 25,
  CallQuality2: 50,
  TurnConnectivity: 4,
  TurnConnectivity2: 4,
  VideoBandwidth: 33,
  Location: 0.5,
  BandwidthSpeed: 35,
  BandwidthSpeedAll: 400,
  Ping: 7.5,
  PingAll: 25,
  NetworkInformation: 0.2,
  VideoQuality: 33,
  WebexVideoQuality: 43,
  TwilioVideoQuality: 70,
  DNSLookup: 10,
  VideoPlayback: 0.05,
  RCVTURNServers: 25,
  GetUserMedia: 0.3,
  DNSSetup: 25,
  VideoP2P: 45,
  PrivateConnections: 5.5,
  DNSSettings: 6.6,
  GPSLocation: 4,
  HardwareAvailability: 4,
  DeviceState: 0.5,
};
