export enum TestNames {
  Throughput,
  CallQuality,
  TurnConnectivity,
  TurnConnectivity2,
  VideoBandwidth,
  VideoBandwidth2,
  Location,
  BandwidthSpeed,
  Ping,
  NetworkInformation,
  VideoQuality,
  DNSLookup,
  VideoPlayback,
  RCVTURNServers,
  GetUserMedia,
  DNSSetup,
  VideoP2P,
  PrivateConnections,
  DNSSettings,
  GPSLocation,
  HardwareAvailability,
  DeviceState,
  MediaServerIp
}

export enum AdditionalTestNames {
  VideoPlayback,
  RCVTURNServers,
  GetUserMedia,
  DNSSetup,
  DNSSettings,
}

export enum TestTypes {
  All,
  Tcp,
  Udp,
  Tls,
  Bandwidth,
  Call,
  Speed,
  Ping,
  Location,
  NetworkInformation,
}
