import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";

interface IDefaultProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
}

const Default = (props: IDefaultProps) => {
  const { theme, testCall, config, mainColor, currentThresholds } = props;
  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, meta, mosInbound } = testCall;
  const codec = testCall.codec || (config as any).CallQuality?.codec;

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  // mos calculation is not twilio so no mos2 value
  // so there is an mosInbound and mosOutbound(mark/mos1)
  // this is combined mode so average mosInbound and mosOutbound(mark/mos1)

  const mosCombined = mark && mosInbound ? (mark + mosInbound) / 2 : mark ? mark : mosInbound;

  // const overall =
  //   mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";
  // const mosValue = mark
  //   ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>`
  //   : "No Data";

  const overall =
    mosCombined > 4
      ? "Excellent"
      : mosCombined > 3
      ? "Good"
      : mosCombined > 2
      ? "Fair"
      : mosCombined > 1
      ? "Poor"
      : "Bad";
  const mosValue = mosCombined
    ? `${mosCombined.toFixed(1)} <span className="result-extra">(${overall})</span>`
    : "No Data";
  const packetLoss = avgPl > avgOutPl ? avgPl : avgOutPl;
  const jitter = avgJit > avgJitOut ? avgJit : avgJitOut;

  const titleCodec = codec === "G711" ? "G.711" : "";

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={t("tests:call-quality.title", { codec: titleCodec ? " - " + titleCodec : "" })}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
      errorsCount={meta?.errorsCount}
    >
      <div className={`tests-card-content tst-call-quality`}>
        <table>
          <tbody>
            <tr className="tst-mos" id="CQC-r-MOSScore">
              <td>{t("MOS Score")}</td>
              <td id="CQC-c-mosValue">
                <RenderValues
                  {...defaultProps}
                  value={mosValue}
                  color={
                    currentThresholds && currentThresholds.mark
                      ? getColorValue(
                          {
                            ...colorTypes,
                            // value: mark,
                            value: mosCombined,
                            warningValue: currentThresholds.mark.warning,
                            errorValue: currentThresholds.mark.error,
                          },
                          currentThresholds.mark.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-round-trip" id="CQC-r-RoundTrip">
              <td>{t("Round Trip")}</td>
              <td id="CQC-c-avgRtt">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value} <span>ms</span>`}
                  value={avgRtt}
                  color={
                    currentThresholds && currentThresholds.avgRtt
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgRtt,
                            warningValue: currentThresholds.avgRtt.warning,
                            errorValue: currentThresholds.avgRtt.error,
                          },
                          currentThresholds.avgRtt.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-packet-loss" id="CQC-r-packetLoss">
              <td>{t("Packet Loss")}</td>
              <td id="CQC-c-avgPl">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(1)}%`}
                  value={packetLoss}
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: packetLoss,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-jitter" id="CQC-r-jitter">
              <td>{t("jitter")}</td>
              <td id="CQC-c-avgJit">
                <div className="value-row-pair">
                  <RenderValues
                    value={jitter}
                    {...defaultProps}
                    color={
                      currentThresholds && currentThresholds.avgJit
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: jitter,
                              warningValue: currentThresholds.avgJit.warning,
                              errorValue: currentThresholds.avgJit.error,
                            },
                            currentThresholds.avgJit.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(Default);
