import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { LocationIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { formatNumber } from "../../../helpers/utils";
import { IDeviceState, ITestsResult } from "twillio-tests/core/TestResults";

interface ILocationTestProps {
  mainColor: string;
  testResult?: ITestsResult;
  theme: any;
  currentTheme?: string;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
}

const initialValues = {
  audio: "-",
  device: "-",
  network: "-",
};
let mediaPathFlag: boolean = true;
const DeviceStateTest = (props: ILocationTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    knowledgeUrl,
    estimateTime,
    testName,
    showInMap,
    currentTheme,
  } = props;
  currentTheme === "livevox" ? (mediaPathFlag = false) : (mediaPathFlag = true);
  const deviceStateTest: IDeviceState =
    testResult && testResult.deviceState ? testResult.deviceState : initialValues;

  const region =
    testResult && testResult.testCall && testResult.testCall.region
      ? testResult.testCall.region
      : "-";

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  const { t } = useTranslation(["common", "tests"]);

  const deviceName = deviceStateTest.device;
  const authorization =
    deviceStateTest.audio !== "-" ? t(`tests:devicestate.${deviceStateTest.audio}`) : "-";
  const network = deviceStateTest.network;

  return (
    <TestHeader
      icon={<LocationIco />}
      title={t("tests:devicestate.title")}
      tooltip={t("tests:devicestate.tooltip")}
      error={""}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min">
        <table>
          <tbody>
            <tr className="tst-city" id="DST-r-authorization">
              <td>{t("tests:devicestate.audio")}</td>
              <td id="DST-c-authorization">
                {<RenderValues value={authorization} {...defaultProps} />}
              </td>
            </tr>
            <tr className="tst-country" id="DST-r-deviceName">
              <td>{t("tests:devicestate.device")}</td>
              <td id="DST-c-deviceName">{<RenderValues value={deviceName} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-org" id="DST-r-network">
              <td>{t("tests:devicestate.network")}</td>
              <td id="DST-c-network">{<RenderValues value={network} {...defaultProps} />}</td>
            </tr>

            {mediaPathFlag && region !== "-" && region !== "roaming" && (
              <tr className="tst-org" id="DST-r-region">
                <td>{t("tests:devicestate.mediapath")}</td>
                <td id="DST-c-region">{<RenderValues value={region} {...defaultProps} />}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(DeviceStateTest);
