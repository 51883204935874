import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { BandwidthIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ISpeed, ITestsResult } from "twillio-tests/core/TestResults";

interface IBandwidthTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: ISpeed = {
  download: undefined,
  jitter: undefined,
  upload: undefined,
  region: undefined,
};

const Mbps = (value: string | number) => `${formatNumber(Number(value))} <span>Mbps</span>`;

const BandwidthBestTest = (props: IBandwidthTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName } =
    props;
  const speedTest = testResult && testResult.speedTest ? testResult.speedTest.best : initialValues;
  const currentThresholds = colorThresholds?.BandwidthSpeed || null;

  const { download, jitter, upload, region, error } = speedTest;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<BandwidthIco />}
      title={t("tests:bandwidth.title")}
      tooltip={t("tests:bandwidth.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-bandwidth-speed">
        <table>
          <tbody>
            <tr className="tst-best-region" id="BWTA-r-region">
              <td>{t("tests:bandwidthspeed:best-region")}</td>
              <td id="BWTA-c-region">
                <RenderValues {...defaultProps} value={region} />
              </td>
            </tr>
            <tr className="tst-uplink" id="BWTA-r-uplink">
              <td>{t("uplink")}</td>
              <td id="BWTA-c-upload">
                <RenderValues
                  {...defaultProps}
                  value={upload}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.upload
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: upload,
                            warningValue: currentThresholds.upload.warning,
                            errorValue: currentThresholds.upload.error,
                          },
                          currentThresholds.upload.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-downlink" id="BWTA-r-downlink">
              <td>{t("downlink")}</td>
              <td id="BWTA-c-downlink">
                <RenderValues
                  {...defaultProps}
                  value={download}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.download
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: download,
                            warningValue: currentThresholds.download.warning,
                            errorValue: currentThresholds.download.error,
                          },
                          currentThresholds.download.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-jitter" id="BWTA-r-jitter">
              <td>{t("jitter")}</td>
              <td id="BWTA-c-jitter">
                <RenderValues
                  {...defaultProps}
                  value={jitter}
                  color={
                    currentThresholds && currentThresholds.jitter
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: jitter,
                            warningValue: currentThresholds.jitter.warning,
                            errorValue: currentThresholds.jitter.error,
                            warningValueMax: currentThresholds.jitter.warningMax,
                            warningValueMin: currentThresholds.jitter.warningMin,
                          },
                          currentThresholds.jitter.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(BandwidthBestTest);
