import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { VideoBandwidthIco, VideoQualityIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ITestsResult, IVideoBandwidth } from "twillio-tests/core/TestResults";

interface IVideoBandwidthTestProps {
  videoQualityMode?: boolean;
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  testTitle?: string;
}

const initialValues: IVideoBandwidth = {
  bandwidthEstimate: undefined,
  bitrate: undefined,
  jitter: undefined,
  roundTripTime: undefined,
  fractionLoss: undefined,
};

const VideoBandwidthTest = (props: IVideoBandwidthTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    knowledgeUrl,
    estimateTime,
    colorThresholds,
    videoQualityMode,
    testName,
    testTitle,
  } = props;

  const getTestName = (): string => {
    if (videoQualityMode) {
      return "videoQualityTest";
    } else if (testName && testName === "VideoBandwidth2") {
      return "videoBandwidthTest2";
    } else {
      return "videoBandwidthTest";
    }
  };

  const videoBandwidthTest = (testResult && testResult[getTestName()]) || initialValues;
  const currentThresholds: any = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoBandwidth;

  const { bandwidthEstimate, jitter, roundTripTime, fractionLoss, error } = videoBandwidthTest;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const bitrate = isNaN(Number(videoBandwidthTest.bitrate))
    ? undefined
    : videoBandwidthTest.bitrate;

  const formatBandwidthEstimate = (value: number | string) => {
    if (!bitrate) {
      return `${formatNumber(Number(value))} <span>kbps</span>`;
    } else if (!value) {
      return `${formatNumber(Number(bitrate))} <span>kbps</span>`;
    } else {
      return `<div class='bandwidth-estimate-row'>
        <div>${formatNumber(Number(value))}</div>
        <div>
          <span>bitrate</span>
          ${formatNumber(Number(bitrate))}
          <span>kbps</span>
        </div>
      </div>`;
    }
  };

  const getTestTitle = (): string => {
    if (videoQualityMode) {
      return t("tests:video.quality");
    } else if (testTitle) {
      return t(testTitle);
    } else {
      return t("tests:video:bandwidth");
    }
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = getTestTitle();
  return (
    <TestHeader
      icon={videoQualityMode ? <VideoQualityIco /> : <VideoBandwidthIco />}
      title={title}
      tooltip={title}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-video-bandwidth">
        <table>
          <tbody>
            {!videoQualityMode && (
              <tr className="tst-bandwidthEstimate" id="vb-r-estimated">
                <td>{t("estimated")}</td>
                <td id="vb-c-estimated">
                  <RenderValues
                    {...defaultProps}
                    value={bandwidthEstimate}
                    formatting={formatBandwidthEstimate}
                    color={
                      currentThresholds?.bandwidthEstimate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: bandwidthEstimate,
                              warningValue: currentThresholds.bandwidthEstimate.warning,
                              errorValue: currentThresholds.bandwidthEstimate.error,
                            },
                            currentThresholds.bandwidthEstimate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            )}
            {videoQualityMode && (
              <tr className="tst-bandwidthEstimate" id="vb-r-bitrate">
                <td>{t("bitrate")}</td>
                <td id="vb-c-bitrate">
                  <RenderValues
                    {...defaultProps}
                    value={bitrate}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>kbps</span>`
                    }
                    color={
                      currentThresholds?.bitrate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: bitrate,
                              warningValue: currentThresholds.bitrate.warning,
                              errorValue: currentThresholds.bitrate.error,
                            },
                            currentThresholds.bitrate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            )}
            {!videoQualityMode && (
              <tr className="tst-jitter" id="vb-r-jitter">
                <td>{t("jitter")}</td>
                <td id="vb-c-jitter">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: string | number) =>
                      `${Number(value).toFixed(0)} <span>ms</span>`
                    }
                    value={jitter}
                    color={defaultProps.color}
                  />
                </td>
              </tr>
            )}
            {!videoQualityMode && (
              <tr className="tst-roundTripTime" id="vb-r-roundTrip">
                <td>{t("Round Trip")}</td>
                <td id="vb-c-roundTrip">
                  <RenderValues
                    {...defaultProps}
                    value={roundTripTime}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>ms</span>`
                    }
                    color={
                      currentThresholds?.roundTripTime
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: roundTripTime,
                              warningValue: currentThresholds.roundTripTime.warning,
                              errorValue: currentThresholds.roundTripTime.error,
                            },
                            currentThresholds.roundTripTime.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            )}
            <tr className="tst-fractionLoss" id="vb-r-packetLoss">
              <td>{t("Packet Loss")}</td>
              <td id="vb-c-packetLoss">
                <RenderValues
                  {...defaultProps}
                  value={fractionLoss}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>%</span>`
                  }
                  color={
                    currentThresholds?.fractionLoss
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: fractionLoss,
                            warningValue: currentThresholds.fractionLoss.warning,
                            errorValue: currentThresholds.fractionLoss.error,
                          },
                          currentThresholds.fractionLoss.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(VideoBandwidthTest);
