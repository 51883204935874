import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { ThroughputIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IThroughputTest, ITestsResult } from "twillio-tests/core/TestResults";
interface IThroughputTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config: any;
}

const initialValues: IThroughputTest = {
  averageBandwidth: undefined,
  concurrentConnections: undefined,
  highestBandwidth: undefined,
  lowestBandwidth: undefined,
  maxThroughputs: undefined,
  minThroughputs: undefined,
};

const ThroughputTest = (props: IThroughputTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    testName,
    config,
  } = props;
  const throughputTest =
    (testResult && (testResult as any)?.bandwidthTest) || testResult?.throughput || initialValues;
  const currentThresholds = colorThresholds?.Throughput || null;
  const {
    error,
    averageBandwidth,
    concurrentConnections,
    highestBandwidth,
    lowestBandwidth,
    maxThroughputs,
    minThroughputs,
  } = throughputTest;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };
  const showSessions = config.Throughput?.codec?.toLowerCase() !== "none";
  const formatting = !showSessions
    ? (value: number | string) => `${formatNumber(Number(value))} <span>kbps</span>`
    : undefined;
  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<ThroughputIco />}
      title={t("tests:throughput.title")}
      tooltip={t("tests:throughput.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--throughput tst-throughput">
        <table>
          {showSessions && (
            <thead>
              <tr>
                <th />
                <th>kbps</th>
                <th>{t("session", { count: 0 })}</th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr id="TT-r-highestBandwidth">
              <td>{t("maximum")}</td>
              <td id="TT-c-highestBandwidth">
                <RenderValues
                  {...defaultProps}
                  tstId="tst-max-kbps"
                  value={highestBandwidth}
                  formatting={formatting}
                  color={
                    currentThresholds?.highestBandwidth
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: highestBandwidth,
                            warningValue: currentThresholds.highestBandwidth.warning,
                            errorValue: currentThresholds.highestBandwidth.error,
                          },
                          currentThresholds.highestBandwidth.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {showSessions && (
                <td id="TT-c-maxThroughputs">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-max-sessions"
                    value={maxThroughputs}
                    color={
                      currentThresholds?.maxThroughputs
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: maxThroughputs,
                              warningValue: currentThresholds.maxThroughputs.warning,
                              errorValue: currentThresholds.maxThroughputs.error,
                            },
                            currentThresholds.maxThroughputs.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr id="TT-r-average">
              <td>{t("average")}</td>
              <td id="TT-c-averageBandwidth">
                <RenderValues
                  {...defaultProps}
                  tstId="tst-avg-kbps"
                  value={averageBandwidth}
                  formatting={formatting}
                  color={
                    currentThresholds?.averageBandwidth
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: averageBandwidth,
                            warningValue: currentThresholds.averageBandwidth.warning,
                            errorValue: currentThresholds.averageBandwidth.error,
                          },
                          currentThresholds.averageBandwidth.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {showSessions && (
                <td id="TT-c-concurrentConnections">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-avg-sessions"
                    value={concurrentConnections}
                    color={
                      currentThresholds?.concurrentConnections
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: concurrentConnections,
                              warningValue: currentThresholds.concurrentConnections.warning,
                              errorValue: currentThresholds.concurrentConnections.error,
                            },
                            currentThresholds.concurrentConnections.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr id="TT-r-minimum">
              <td>{t("minimum")}</td>
              <td id="TT-c-lowestBandwidth">
                <RenderValues
                  {...defaultProps}
                  tstId="tst-min-kbps"
                  value={lowestBandwidth}
                  formatting={formatting}
                  color={
                    currentThresholds?.lowestBandwidth
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: lowestBandwidth,
                            warningValue: currentThresholds.lowestBandwidth.warning,
                            errorValue: currentThresholds.lowestBandwidth.error,
                          },
                          currentThresholds.lowestBandwidth.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {showSessions && (
                <td id="TT-c-minThroughputs">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-min-sessions"
                    value={minThroughputs}
                    color={
                      currentThresholds?.minThroughputs
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: minThroughputs,
                              warningValue: currentThresholds.minThroughputs.warning,
                              errorValue: currentThresholds.minThroughputs.error,
                            },
                            currentThresholds.minThroughputs.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(ThroughputTest);
