import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";

interface INiceInContactProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
}

const niceInContactLookupLocations = {
  "216.20.235.68": "dalacwrtc01",
  "216.20.235.69": "dalacwrtc02",
  "216.20.235.90": "dalacwrtc03",
  "216.20.235.91": "dalacwrtc04",
  "216.20.235.92": "dalacwrtc05",
  "216.20.235.93": "dalacwrtc06",
  "216.20.235.94": "dalacwrtc07",
  "216.20.237.68": "laxacwrtc01",
  "216.20.237.69": "laxacwrtc02",
  "216.20.237.90": "laxacwrtc03",
  "216.20.237.91": "laxacwrtc04",
  "216.20.237.92": "laxacwrtc05",
  "216.20.237.93": "laxacwrtc06",
  "216.20.237.94": "laxacwrtc07",
  "61.14.30.153": "sgpacwrtc01",
  "210.57.57.133": "tkyacwrtc01",
  "103.13.81.153": "sydacwrtc01",
  "103.13.81.139": "melacwrtc01",
  "154.60.103.169": "fraacwrtc01",
  "154.48.222.169": "munacwrtc01",
  "200.15.3.171": "spracwrtc01",
  "18.228.54.36": "asaacwrtc01",
  // "216.20.233.69": "via-acwrtc01", // not working
};

const twoColsTdStyle = (error: boolean, color: string) => (error ? { color } : {});

const NiceInContact = (props: INiceInContactProps) => {
  const { theme, testCall, config, mainColor, currentThresholds } = props;
  const {
    avgJit,
    avgPl,
    avgOutPl,
    avgRtt,
    mark,
    avgJit2,
    avgPl2,
    avgOutPl2,
    avgRtt2,
    mark2,
    remoteAddress,
    remoteAddress2,
    error,
    error1,
    error2,
    meta,
  } = testCall;

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  const overall =
    mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";
  const overall2 =
    mark2 > 4 ? "Excellent" : mark2 > 3 ? "Good" : mark2 > 2 ? "Fair" : mark2 > 1 ? "Poor" : "Bad";

  const mosValue =
    mark && mark != "N/A"
      ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>`
      : "No Data";

  const mosValue2 = mark2
    ? `${mark2.toFixed(1)} <span className="result-extra">(${overall2})</span>`
    : "No Data";

  const twoColsMode =
    (config?.options?.dc && config?.options?.dc?.indexOf(",") !== -1) ||
    ((remoteAddress || error1) && (remoteAddress2 || error2));

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={t("tests:call-quality.title")}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      errorsCount={meta?.errorsCount}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
    >
      <div className={`tests-card-content tst-call-quality`}>
        <table>
          <tbody>
            {twoColsMode && (
              <tr className="two-cols-mode-header" id="CQC-r-error">
                <td></td>
                <td id="CQC-c-error" style={twoColsTdStyle(error1, colorTypes.errorColor)}>
                  {niceInContactLookupLocations[
                    typeof remoteAddress === "string" ? remoteAddress.split(":")[0] : remoteAddress
                  ] ||
                    remoteAddress ||
                    ""}
                </td>
                <td style={twoColsTdStyle(error2, colorTypes.errorColor)}>
                  {niceInContactLookupLocations[
                    typeof remoteAddress2 === "string"
                      ? remoteAddress2.split(":")[0]
                      : remoteAddress2
                  ] ||
                    remoteAddress2 ||
                    ""}
                </td>
              </tr>
            )}
            <tr className="tst-mos" id="CQC-r-MOSScore">
              <td>{t("MOS Score")}</td>
              <td id="CQC-c-mosValue">
                <RenderValues
                  {...defaultProps}
                  value={mosValue}
                  color={
                    currentThresholds && currentThresholds.mark
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: mark,
                            warningValue: currentThresholds.mark.warning,
                            errorValue: currentThresholds.mark.error,
                          },
                          currentThresholds.mark.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {twoColsMode && (
                <td id="CQC-c-mosValue2">
                  <RenderValues
                    {...defaultProps}
                    value={mosValue2}
                    color={
                      currentThresholds && currentThresholds.mark
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: mark2,
                              warningValue: currentThresholds.mark.warning,
                              errorValue: currentThresholds.mark.error,
                            },
                            currentThresholds.mark.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr className="tst-round-trip" id="CQC-r-roundTrip">
              <td>{t("Round Trip")}</td>
              <td id="CQC-c-avgRtt">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value} <span>ms</span>`}
                  value={avgRtt}
                  color={
                    currentThresholds && currentThresholds.avgRtt
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgRtt,
                            warningValue: currentThresholds.avgRtt.warning,
                            errorValue: currentThresholds.avgRtt.error,
                          },
                          currentThresholds.avgRtt.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {twoColsMode && (
                <td id="CQC-c-avgRtt2">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value} <span>ms</span>`}
                    value={avgRtt2}
                    color={
                      currentThresholds && currentThresholds.avgRtt
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgRtt2,
                              warningValue: currentThresholds.avgRtt.warning,
                              errorValue: currentThresholds.avgRtt.error,
                            },
                            currentThresholds.avgRtt.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr className="tst-packet-loss" id="CQC-r-packetLoss">
              <td>
                {t("inbound")} {t("Packet Loss")}
              </td>
              <td id="CQC-c-avgPl">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(1)}%`}
                  value={avgPl}
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgPl,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {twoColsMode && (
                <td id="CQC-c-avgPl2">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgPl2}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgPl2,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr className="tst-packet-loss" id="CQC-r-outb-packetLoss">
              <td>
                {t("outbound")} {t("Packet Loss")}
              </td>
              <td id="CQC-c-outb-avgOutPl">
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(1)}%`}
                  value={avgOutPl}
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgOutPl,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              {twoColsMode && (
                <td id="CQC-c-outb-avgOutPl2">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgOutPl2}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgOutPl2,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              )}
            </tr>
            <tr className="tst-jitter" id="CQC-r-jitter">
              <td>{t("jitter")}</td>
              <td id="CQC-c-avgJit">
                <div className="value-row-pair">
                  <RenderValues
                    value={avgJit}
                    {...defaultProps}
                    color={
                      currentThresholds && currentThresholds.avgJit
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgJit,
                              warningValue: currentThresholds.avgJit.warning,
                              errorValue: currentThresholds.avgJit.error,
                            },
                            currentThresholds.avgJit.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </div>
              </td>
              {twoColsMode && (
                <td id="CQC-c-avgJit2">
                  <div className="value-row-pair">
                    <RenderValues
                      value={avgJit2}
                      {...defaultProps}
                      color={
                        currentThresholds && currentThresholds.avgJit
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgJit2,
                                warningValue: currentThresholds.avgJit.warning,
                                errorValue: currentThresholds.avgJit.error,
                              },
                              currentThresholds.avgJit.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default withTheme()(NiceInContact);
