import { ICheckBrowser } from "./core/TestResults";
import { TestConfiguration } from "./core/testConfiguration";
import { LogMessage } from "./core/TestRunInfo";
import * as uaParser from "ua-parser-js";
export default function run(config: TestConfiguration, logMessage: LogMessage): ICheckBrowser {
  let canRun = true;
  const browserInfo = getBrowserInfo();
  const browserVersion = `${browserInfo.browser}-${browserInfo.version}-${browserInfo.os}`;
  if (config.browserCheckRegexp) {
    const regex = new RegExp(config.browserCheckRegexp, "i");
    canRun = regex.test(browserVersion);
  }

  logMessage("=== Check Browser Test ===");

  logMessage(
    {
      Browser: `${browserInfo.browser}`,
      Version: `${browserInfo.version}`,
      // SupportsUnifiedPlan: `${browserInfo.supportsUnifiedPlan}`,
    },
    canRun ? "green" : "red"
  );

  browserInfo.canRun = canRun;
  return browserInfo;
}

/**
 * Browser detector.
 *
 * @return {object} result containing browser and version
 *     properties.
 */
export function detectBrowser(): ICheckBrowser {
  const { navigator } = window as any;

  // Returned result object.
  const result: ICheckBrowser = {
    browser: undefined,
    version: undefined,
    supportsUnifiedPlan: undefined,
    os: undefined,
    message: undefined,
  };

  // Fail early if it's not a browser
  if (typeof window === "undefined" || !window.navigator) {
    result.browser = "Not a browser.";
    return result;
  }

  if (navigator.mozGetUserMedia) {
    // Firefox.
    result.browser = "firefox";
    result.version = extractVersion(navigator.userAgent, /Firefox\/(\d+)\./, 1);
  } else if (
    navigator.userAgent.match("CriOS") ||
    navigator.webkitGetUserMedia ||
    /Google Inc/.test(navigator.vendor) ||
    (!window.isSecureContext && window.webkitRTCPeerConnection && !window.RTCIceGatherer)
  ) {
    // Chrome, Chromium, Webview, Opera.
    // Version matches Chrome/WebRTC version.
    // Chrome 74 removed webkitGetUserMedia on http as well so we need the
    // more complicated fallback to webkitRTCPeerConnection.
    result.browser = "chrome";
    result.version = extractVersion(navigator.userAgent, /Chrom(e|ium)\/(\d+)\./, 2);
  } else if (navigator.mediaDevices && navigator.userAgent.match(/Edg(e)\/(\d+).(\d+)$/)) {
    // Edge.
    result.browser = "edge";
    result.version = extractVersion(navigator.userAgent, /Edg(e)\/(\d+).(\d+)$/, 2);
  } else if (window.RTCPeerConnection && navigator.userAgent.match(/AppleWebKit\/(\d+)\./)) {
    // Safari.
    result.browser = "safari";
    result.version = extractVersion(navigator.userAgent, /AppleWebKit\/(\d+)\./, 1);
    result.supportsUnifiedPlan =
      window.RTCRtpTransceiver && "currentDirection" in window.RTCRtpTransceiver.prototype;
  } else {
    // Default fallthrough: not supported.
    result.browser = "Not a supported browser.";
    return result;
  }

  const osDetails = getOS();
  result.os = osDetails.os;
  result.message = osDetails.message;
  return result;
}

export function getBrowserInfo(): ICheckBrowser {
  const { navigator } = window as any;
  const result: ICheckBrowser = {
    browser: undefined,
    version: undefined,
    supportsUnifiedPlan: undefined,
    os: undefined,
    message: undefined,
  };

  // Fail early if it's not a browser
  if (typeof window === "undefined" || !window.navigator) {
    result.browser = "Not a browser.";
    return result;
  }
  const ua = uaParser(navigator.userAgent);

  const parts = {
    browser: ua.browser.name || "unknown",
    version: ua.browser.version ? parseInt(ua.browser.version, 10) : "-1",
    os: ua.os.name?.replace(" ", ""),
    userAgent: navigator.userAgent,
    nameVersion: ua.browser.name + "/" + ua.browser.version,
    nameOs: ua.browser.name + "/" + ua.os.name,
    nameVersionOs: ua.browser.name + "/" + ua.browser.version + "/" + ua.os.name,
    majorVersion: undefined,
  };
  return parts;
}

/**
 * Get Operation System
 */
export function getOS() {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];

  let os: string = "";
  let message: string = "";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "MacOS";
    message =
      "Please download a supported browser: <br><a href='https://www.google.com/chrome/' target='_blank'>Google Chrome</a>";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
    message =
      "Please use your Safari browser or install  <br><a href='https://www.google.com/chrome/' target='_blank'>Google Chrome</a>";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
    message =
      "Please download a supported browser:  <br><a href='https://www.google.com/chrome/' target='_blank'>Google Chrome</a> or <a href='https://www.microsoft.com/en-us/windows/microsoft-edge' target='_blank'>Microsoft Edge</a>";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
    message =
      "Please download a supported browser:  <br><a href='https://www.google.com/chrome/' target='_blank'>Google Chrome</a>";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return { os: os, message: message };
}

/**
 * Extract browser version out of the provided user agent string.
 *
 * @param {!string} uastring userAgent string.
 * @param {!string} expr Regular expression used as match criteria.
 * @param {!number} pos position in the version string to be returned.
 * @return {!number} browser version.
 */
export function extractVersion(uastring: any, expr: any, pos: any): number | undefined {
  const match = uastring.match(expr);
  return match && match.length >= pos ? parseInt(match[pos], 10) : undefined;
}
