import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import { customTranslateText, getQueryParameters, translateText } from "../../helpers/utils";
import { IField } from "../../interfaces";

interface ExplanationMessageProps {
  classes?: any;
  fields?: IField[];
  theme?: string;
  config?: any;
  textDictionary?: Record<string, string>;
  testResult?: any;
  testIsLoading?: boolean;
  explanationMessageColor?: string;
}

const styles = (theme: any) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 1,
  },
  message: {
    color: theme.customColors.successColor,
    fontSize: 14,
    fontWeight: 600,
  },
  message_mend: {
    fontWeight: 500,
  },
  message_amazon: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  message_engauge: {
    color: theme.palette.secondary.main,
  },
  message_salesloft: {
    fontFamily: "Source",
    fontWeight: 500,
  },
  message_avail: {
    marginBottom: theme.spacing.unit * 2,
  },
  message_avatour: {
    color: "#fff",
  },
  message_dialpad: {
    color: "rgb(124, 82, 255)",
    fontSize: "15px",
    fontWeight: 100,
  },
  message_puzzel: {
    color: "#fff",
    padding: "1rem",
    borderRadius: "8px",
    textAlign: "center" as "center",
    backgroundColor: "rgb(247, 2, 169)",
    background: "linear-gradient(0deg, rgba(230,19,103,1) 0%, rgba(247,2,169,1) 100%)",
  },
  message_zaurus: {
    color: theme.palette.secondary.main,
  },
});

function mapStateToProps(state: any) {
  return {
    fields: state.document.fields || [],
    theme: state.document.theme,
    textDictionary: state.tests.textDictionary,
    config: state.tests.config,
    testResult: state.tests.testResult,
    testIsLoading: state.document.testIsLoading,
    explanationMessageColor: state.document.layout.explanationMessageColor,
  };
}

const ExplanationMessage = (props: ExplanationMessageProps) => {
  const {
    classes,
    textDictionary,
    fields = [],
    testResult,
    testIsLoading,
    theme,
    explanationMessageColor,
    config,
  } = props;
  if (!textDictionary) {
    throw new Error("Missing parameter");
  }
  const { t, i18n } = useTranslation(["tests"]);
  const message = customTranslateText(
    t(`tests:textDictionary.${config?.theme}.email-reason-explanation-message`),
    t("tests:explanation.email-reason-explanation-message"),
    i18n.exists(`tests:textDictionary.${config?.theme}.email-reason-explanation-message`)
  );

  const messageAboutUseDevices = customTranslateText(
    t(`tests:textDictionary.${config?.theme}.use-device's-explanation-message`),
    t("tests:explanation.email-reason-explanation-message"),
    i18n.exists(`tests:textDictionary.${config?.theme}.use-device's-explanation-message`)
  );

  let userReasonCondition = true;
  const queryParams = getQueryParameters();
  if (queryParams.user && queryParams.reason) {
    userReasonCondition = false;
  }

  if (
    config?.showInstructions ||
    (fields.find((x) => x.name === "email") &&
      userReasonCondition &&
      !testResult &&
      !testIsLoading &&
      config?.theme !== "livevox")
  ) {
    return (
      <div data-html2canvas-ignore className={classes.root}>
        <Typography
          className={`${classes.message} ${classes[`message_${theme}`]}`}
          style={explanationMessageColor ? { color: explanationMessageColor } : {}}
        >
          {message}
        </Typography>

        {i18n.exists(
          `tests:textDictionary.${config?.options.config}.use-device's-explanation-message`
        ) && (
          <Typography
            className={`${classes.message} ${classes[`message_${theme}`]}`}
            style={explanationMessageColor ? { color: explanationMessageColor } : {}}
          >
            {messageAboutUseDevices}
          </Typography>
        )}
      </div>
    );
  }
  return null;
};

export default connect(mapStateToProps, null)(withStyles(styles)(ExplanationMessage));
