import React, { useEffect, useRef, useState } from "react";
import { on, off } from "../utils";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
const noop = () => {};
const useMediaDevices = (config?: TestConfiguration) => {
  const [state, setState] = useState(true);
  const [audioPermission, setAudioPermission] = useState(true);
  const [videoPermission, setVideoPermission] = useState(true);
  useEffect(() => {
    let mounted = true;
    const onChange = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          if (mounted) {
            if (config && config.getUserMedia) {
              const { checkAudio, checkVideo } = config.getUserMedia;
              if (checkAudio && checkVideo) {
                setAudioPermission(checkPermission(devices, "audioinput").length ? true : false);
                setVideoPermission(checkPermission(devices, "videoinput").length ? true : false);
              } else if (!checkAudio && checkVideo) {
                setVideoPermission(checkPermission(devices, "videoinput").length ? true : false);
              } else if (!checkVideo && checkAudio) {
                setAudioPermission(checkPermission(devices, "audioinput").length ? true : false);
              } else {
                noop();
              }
            }
          }
        })
        .catch(noop);
    };

    on(navigator.mediaDevices, "devicechange", onChange);
    onChange();
    return () => {
      mounted = false;
      off(navigator.mediaDevices, "devicechange", onChange);
    };
  });

  useEffect(() => {
    if (audioPermission && videoPermission) {
      setState(true);
    } else {
      setState(false);
    }
  }, [audioPermission, videoPermission]);

  const checkPermission = (devices: MediaDeviceInfo[], videoKind: MediaDeviceKind) => {
    return devices
      .filter(({ kind }) => kind === videoKind)
      .map(({ deviceId, label }) => {
        if (deviceId && label) {
          return true;
        } else {
          return false;
        }
      })
      .filter((resp) => resp);
  };

  return state;
};

export default useMediaDevices;
